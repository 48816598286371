export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const RESET = "RESET";
export const DRAWER = "DRAWER";
export const REFERRAL = "REFERRAL";

export const TESTDRAWER = "TESTDRAWER";
export const TESTRESET = "TESTRESET";
export const SETSECTION = "SETSECTION";
export const SUBMITANS = "SUBMITANS";
export const SETQUES = "SETQUES";
export const MARKED = "MARKED";
export const SAVENEXT = "SAVENEXT";
export const CLEARANS = "CLEARANS";
export const LOADING = "LOADING";
export const LOADTEST = "LOADTEST";
export const SUBMITBOX = "SUBMITBOX";
export const TOGGLEDRAWER = "TOGGLEDRAWER";

export const NEXTQUES = "NEXTQUES";
export const SHOWSOL = "SHOWSOL";
export const GETQUES = "GETQUES";
export const TIMETAKEN = "TIMETAKEN";
export const PRESET = "PRESET";
