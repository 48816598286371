import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import App from "../../App";
import Backdrop from "../Backdrop";
const PublicRoutes = lazy(() => import("./PublicRoutes"));
const AdminRoutes = lazy(() => import("./AdminRoutes"));
export default function MainRoute() {
	return (
		<Switch>
			<Route path="/" exact component={App} />
			<Suspense fallback={<Backdrop open={true} />}>
				<PublicRoutes />
				<Suspense fallback={<Backdrop open={true} />}>
					<AdminRoutes />
				</Suspense>
			</Suspense>
		</Switch>
	);
}
